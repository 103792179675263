<template>
  <validation-provider
    v-slot="validationContext"
    :ref="`service-${index}`"
    rules="required"
    :vid="`service-${index}`"
    :name="`Value`"
  >
    <b-form-group
      label-for="ServiceComponent"
    >
      <v-select
        :value="value"
        :placeholder="placeholder"
        :options="options"
        label="text"
        :clearable="false"
        append-to-body
        :calculate-position="withPopper"
        :loading="isLoading"
        :reduce="(option) => option.value"
        @input="$emit('input', $event)"
      >
        <template v-slot:option="option">
          {{ option.text }}  
          <b-badge
            v-if="option.type"
            variant="primary"
            class="mr-50 ml-2"
          >
            {{ option.type }}
          </b-badge>
          <b-badge
            v-if="option.role"
            variant="secondary"
          >
            {{ option.role }}
          </b-badge>
        </template>
      </v-select>
      <b-form-invalid-feedback
        :state="
          getValidationState(
            validationContext
          )
        "
      >
        {{
          validationContext.errors[0]
        }}
      </b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>
        
<script>
import {
  BFormGroup,
  BBadge,
  BFormInvalidFeedback
} from "bootstrap-vue";
import vSelect from "vue-select";
import { makeErrorToast } from "@/libs/utils";
import { mapGetters } from "vuex";
import { createPopper } from '@popperjs/core';
import { ValidationProvider } from "vee-validate";
import { getValidationState } from "@/libs/utils";
export default {
  name: 'AdvancedService',
  components: {
    BFormGroup,
    vSelect, 
    BBadge,
    BFormInvalidFeedback,
    ValidationProvider
  },
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    service: {
      type: Object,
      default: () => {}
    },
    rule: {
      type: Object
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      options: [],
      isLoading: false
    };
  },
  computed:{
    ...mapGetters("champions", ["currentProgram"]),
    placeholder () {
        return this.service.placeholder || 'Any';
    }
  },
  watch: {
    service: {
      handler() {
        this.loadServiceCall();
      }, 
      deep: true,
      immediate: true
    }
  },
  methods: {
    async loadServiceCall() {
      try {
        this.isLoading = true;
        const module = this.service.module;
        const serviceAction = this.service.endpoint;
        const { default: service } = await import(`@/services/${module}`);
        const ref = service[serviceAction];

        if(typeof ref === 'function') {
          const params = typeof this.service.params === 'function' ? this.service.params(this.currentProgram, this.rule) : [];
          const response = await service[serviceAction](...params);
          const {data} = response;
          this.options = this.service.optionsFilterFn(data, this.rule);
        }
      } catch (e) {
        this.$toast(makeErrorToast(e.response));
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
    getRequiredParams () {
      let params = [];
        if(this.service.params && this.service.params.includes('programId')) {
          params = [
            this.$route.params.id
          ];
        }
        if(this.service.params && this.service.params.includes('applicationSetId')) {
          params = [
            this.$route.params.id, 
            this.currentProgram.application_set.id
          ];
        }
        if(this.service.params && this.service.params.includes('surveyId')) {
          params = [
            this.surveyId
          ];
        }
        return params;
    },
    withPopper(dropdownList, component) {
      dropdownList.style.width = 'auto';
      dropdownList.style.maxWidth = "50vw";
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'bottom',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle(
                'drop-up',
                state.placement === 'top'
              );
            },
          },
        ],
      });
      return () => popper.destroy();
    }
  },
  setup() {
    return {
      getValidationState
    };
  }
};
</script>
        